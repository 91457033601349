import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueI18n from 'vue-i18n';
import ru from './localization/ru';
import VeeValidate, { Validator } from 'vee-validate';

Vue.config.productionTip = false

Validator.extend('name', {
  getMessage: 'First name required',
  validate: value => value.length >= 2
});

Validator.extend('email', {
  getMessage: 'Email required',
  validate: value => value.length >= 2
});

Validator.extend('lastNAme', {
  getMessage: 'Last name required',
  validate: value => value.length >= 2
});

Vue.use(VueAxios, axios);
Vue.use(VueI18n);
Vue.use(VeeValidate);

const locale = 'ru';
const i18n = new VueI18n({
  locale,
  messages: {
    ru
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="modal-wrapper" @click.stop="$emit('close')">
    <div class="modal" @click.stop>
      <slot name="header"/>
      <slot name="main"/>
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal'
}
</script>

<style lang="scss" scoped>
  .modal-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(10, 10, 20, 0.8);
  }

  .modal {
    max-width: 95%;
    max-height: 95vh;
    padding: 15px;
    background-color: #1C133A;
    padding: 40px;
    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
<template>
  <div class="basket-container" @click="$emit('click')">
    <svg class="t706__carticon-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"> <path fill="none" stroke-width="2" stroke-miterlimit="10" d="M44 18h10v45H10V18h10z"></path> <path fill="none" stroke-width="2" stroke-miterlimit="10" d="M22 24V11c0-5.523 4.477-10 10-10s10 4.477 10 10v13"></path> </svg>
    <div class="offer-count-block">{{ itemsCount }}</div>
    <div class="total-amount">{{ '$' + totalAmount }}</div>
  </div>
</template>

<script>
export default {
  name: 'BaseBasket',
  props: {
    itemsCount: Number,
    totalAmount: Number
  }
}
</script>


<style lang="scss" scoped>
.basket-container {
    position: fixed;
    top: 100px;
    right: 50px;
    z-index: 4000;
    background-color: rgba(255,255,255,.8);
    border-radius: 50px;
    width: 80px;
    height: 80px;
    cursor: pointer;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
    transition: all .2s ease;

    &:hover {
      transform: scale(1.05);

      .total-amount {
        opacity: 1;
      }
    }
  }

  .t706__carticon-img {
    width: 40px;
    height: 40px;
    padding: 18px 20px 22px;
    stroke: #000;
  }

  .offer-count-block {
    width: 30px;
    height: 30px;
    background-color: red;
    color: #fff;
    border-radius: 30px;
    position: absolute;
    right: -3px;
    bottom: -3px;
    text-align: center;
    line-height: 30px;
    user-select: none;
  }

  .total-amount {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    transform: translateX(0%) translateY(-50%);
    top: 50%;
    right: 130%;
    white-space: nowrap;
    background: white;
    color: black;
    font-size: 15px;
    box-sizing: border-box;
    padding: 9px 13px;
    border-radius: 3px;
    transition: opacity 0.3s linear;
  }

  .total-amount::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: solid transparent;
    border-width: 10px;
    top: 50%;
    right: -19px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-left-color: white;
  }
</style>
<template>
  <div class="bill-wrapper">
    <div class="bill-container">
      <img class="bill-img" src="../../public/custom/images/success.svg" alt="Succes image">
      <div class="notice">Payment succesful!</div>
      <div class="btn" @click="$router.push({name: 'index'})">Confirm</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bill'
}
</script>

<style lang="scss" scoped>
.bill-wrapper {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--bill-bg-color);
}

.bill-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bill-img {
  margin-bottom: 50px;
}

.notice {
  margin-bottom: 70px;
  font-size: calc(20px + 30 * (100vw / 1920));
  text-align: center;
}

.btn {
  width: 280px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 8px;
  background: var(--bill-btn-bg-color);
  color: white;
  cursor: pointer;
  user-select: none;
}
</style>
<template>
  <div class="item-wrapper" :style="{backgroundImage: `url(${require('../../../public/custom/images/' + imageName + '.png')})`}">
    <div class="item-name" v-if="isShowName">{{ itemName }}</div>
    <div class="selling-block">
      <div class="price-block">
        <div class="price">{{ price + '$' }}</div>
        <div class="discount-block">{{ '-' + discount + '%' }}</div>
      </div>
      <div class="pay-btn" @click="$emit('addToBasket', {name: itemName, price: +price * 100})">
        <img src="../../../public/custom/images/bucket.svg" alt="Bucket image">
        Add to Cart
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseItem',
  props: {
    imageName: String,
    price: String,
    discount: String,
    id: String,
    itemName: String,
    isShowName: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.item-wrapper {
  width: inherit;
  height: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all .2s ease;

  &:hover {
    background-position: right;
  }
}

.item-name {
  margin-bottom: 10px;
  max-width: 35%;
  margin-left: 5%;
  font-weight: 600;
  font-size: 22.4762px;
  line-height: 29px;
}

.selling-block {
  padding-left: 5%;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.price-block {
  display: flex;
}

.price {
  margin-right: 20px;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
}

.discount-block {
  width: 36px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFE745;
  border-radius: 6.93333px;
  font-weight: 500;
  font-size: 11px;
  line-height: 11px;
  color: black;
}

.pay-btn {
  width: 132px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--pay-btn-bg-color);
  border-radius: 8px 0px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
  transition: all .2s ease;
  user-select: none;

  img {
    margin-right: 13px;
  }

  &:hover {
    transform: scale(1.005);
  }
}
</style>
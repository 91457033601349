import AuthService from "./Auth";

export class HttpService {
  constructor() {
    this.auth = AuthService;

    window.$httpService = this;
  }
}

export default HttpService;
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import HttpService from './services/index';

export default {
  name: 'App',
  provide: {
    $httpService: new HttpService()
  }
}
</script>

<style lang="scss">
  @import '../src/assets/style.scss';
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

  * {
    color: $main-font-color;
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
  }

  html {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  body {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }

  #app {
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    max-height: max-content;
  }
</style>

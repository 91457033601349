<template>
  <div class="main-wrapper">
    <div class="header">
      <div class="block-container">
        <div class="header-container">
          <img src="../../public/custom/images/logo_key_store.svg" alt="Logo image">
          <nav class="header-nav">
            <a class="header-link" href="#sellers">Best sellers</a>
            <a class="header-link" href="#choise">Our Choice</a>
            <a class="header-link" href="#release">New releases</a>
            <a class="header-link" href="#contacts">Contacts</a>
          </nav>
        </div>

        <div class="header-title-container">
          <div class="header-title-item">Platform of gaming</div>
          <div class="header-title-item">values</div>
          <div class="header-title-item"></div>
        </div>

        <div class="header-offer-container">
          <div class="header-offer-block">
            <div class="header-offer-block-title">Blade&Soul</div>
            <div class="header-offer-block-price-block">
              <div class="header-price">$42</div>
              <div class="header-discount">-28%</div>
            </div>
            <div class="header-offer-btn-block" @click="addToBasket({name: 'Blade&Soul', price: 4200})">
              <img src="../../public/custom/images/bucket.svg" alt="Bucket image">
              <div class="header-offer-btn-text">Add to Cart</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main">
      <div class="block-container">
        <div class="sellers-heading-container" id="sellers">
          <div class="sellers-heading-title">Best Sellers</div>
          <div class="sellers-heading-btn"></div>
        </div>
        <div class="sellers-items-block">
          <div class="sellers-item" v-for="item of sellersItems" :key="item.id">
            <div class="sellers-item-container">
              <base-item
                :discount="item.discount"
                :id="item.id"
                :image-name="item.imageName"
                :price="item.price"
                @addToBasket="addToBasket"
                :item-name="item.name"
              />
            </div>
            <div class="sellers-item-name">{{ item.name }}</div>
          </div>
        </div>

        <div class="advantages-block">
          <div class="advantages-item" v-for="item of advantagesItems" :key="item.image">
            <img class="advantages-img" :src="require(`../../public/custom/images/${item.image}.svg`)" alt="Item image">
            <div class="advantages-title">{{ item.title }}</div>
            <div class="advantages-desc">{{ item.desc }}</div>
          </div>
        </div>

        <div class="choise-block" id="choise">
          <div class="choise-title">Our Choice</div>
          <div class="choise-items-block">
            <div class="choise-items-container">
              <div class="big-choise-item">
                <base-item
                  :discount="'60'"
                  :id="'7'"
                  :price="'8'"
                  :item-name="'Warface 12 items'"
                  :image-name="'7'"
                  @addToBasket="addToBasket"
                  :is-show-name="true"
                />
              </div>
              <div class="small-choise-item">
                <base-item
                  :discount="'25'"
                  :id="'8'"
                  :price="'51'"
                  :item-name="'Blade & Soul 12 items'"
                  :image-name="'8'"
                  @addToBasket="addToBasket"
                  :is-show-name="true"
                />
              </div>
            </div>
            <div class="choise-items-container">
              <div class="small-choise-item">
                <base-item
                  :discount="'46'"
                  :id="'9'"
                  :price="'33'"
                  :item-name="'ArcheAge 12 items'"
                  :image-name="'9'"
                  @addToBasket="addToBasket"
                  :is-show-name="true"
                />
              </div>
              <div class="big-choise-item">
                <base-item
                  :discount="'10'"
                  :id="'10'"
                  :price="'28'"
                  :item-name="'Sekiro 12 items'"
                  :image-name="'10'"
                  @addToBasket="addToBasket"
                  :is-show-name="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="special-offer">
      <div class="special-offer-container block-container">
        <div class="header-offer-block-title">Guild of Heroes <br> Treasure Chest</div>
          <div class="header-offer-block-price-block">
            <div class="header-price">$48</div>
            <div class="header-discount">-30%</div>
          </div>
          <div class="header-offer-btn-block" @click="addToBasket({name: 'Guild of Heroes Treasure Chest', price: 4800})">
            <img src="../../public/custom/images/bucket.svg" alt="Bucket image">
            <div class="header-offer-btn-text">Add to Cart</div>
          </div>
      </div>
    </section>

    <div class="release-container" id="release">
      <div class="block-container">
        <div class="release-title">New releases</div>
        <div class="release-items-block">
          <div class="release-items-container-big">
            <div class="big-release-item-container">
              <div class="big-release-item">
                <base-item
                  :discount="'3'"
                  :id="'11'"
                  :image-name="'11'"
                  :price="'48'"
                  @addToBasket="addToBasket"
                  :item-name="'Mortal Shell 12 items'"
                />
              </div>
              <div class="big-release-item-name">Mortal Shell 12 items</div>
            </div>
          </div>
          <div class="release-items-container-small">
            <div class="small-release-item-container">
              <div class="small-release-item">
                <base-item
                  :discount="'31'"
                  :id="'12'"
                  :image-name="'12'"
                  :price="'31'"
                  @addToBasket="addToBasket"
                  :item-name="'God of night 12 items'"
                />
              </div>
              <div class="big-release-item-name">God of night 12 items</div>
            </div>
            <div class="small-release-item-container">
              <div class="small-release-item">
                <base-item
                  :discount="'31'"
                  :id="'13'"
                  :image-name="'13'"
                  :price="'31'"
                  @addToBasket="addToBasket"
                  :item-name="'Chivalry 12 items'"
                />
              </div>
              <div class="big-release-item-name">Chivalry 12 items</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer" id="contacts">
      <div class="block-container">
        <div class="footer-block">
          <img src="../../public/custom/images/logo_key_store.svg" alt="Logo image">
          <div class="footer-legal-block">
            <div class="legal-desc">Hot cd key deals on games, DLC, bundles and content cards for PC, Nintendo Switch, Playstation and Xbox listed</div>
            <div class="legal-name">Name ©  {{ currentYear }}. All rights reserved.</div>
          </div>
          <div class="footer-nav">
            <a href="#sellers" class="footer-nav-link">Best sellers</a>
            <a href="#choise" class="footer-nav-link">Our Choice</a>
            <a href="#release" class="footer-nav-link">New releases</a>
            <a href="#contacts" class="footer-nav-link">Contacts</a>
          </div>
          <div class="footer-nav">
            <a href="/" class="footer-nav-link">Privacy policy</a>
            <a href="/" class="footer-nav-link">Terms and conditions</a>
          </div>
        </div>
      </div>
    </footer>

    <base-basket
      :items-count="offerItemsCount"
      :total-amount="+(totalAmount / 100).toFixed(0)"
      @click="openOfferModal()"
      v-if="offerItemsCount > 0 && !isShowOfferModal"
    />

    <base-modal v-if="isShowOfferModal" @close="closeOfferModal()">
      <template #header>
        <div class="modal-block">
          <div class="modal-title">Your order:</div>
          <div class="modal-offers-block">
            <div class="modal-offer-item" v-for="item of activeOffersList" :key="item.name">
              <div class="offer-item-name">{{ item.name }}</div>
              <div class="count-block">
                <div class="count-down" @click.stop="countDown(item)">-</div>
                <div class="counter">{{ item.count }}</div>
                <div class="count-up" @click.stop="countUp(item)">+</div>
              </div>
              <div class="amount-block">
                {{ '$' + ((item.price * item.count) / 100).toFixed(0) }}
                <div class="delete-btn" @click="deleteOfferItem(item)">x</div>
              </div>
            </div>
          </div>
          <div @click="startPayment()" class="modal-btn">Checkout: ${{ (totalAmount / 100).toFixed(0) }}</div>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import BaseItem from '../components/base/BaseItem.vue';
import BaseBasket from '../components/base/BaseBasket.vue';
import BaseModal from '../components/base/BaseModal.vue';

export default {
  name: 'index',
  inject: ['$httpService'],
  components: {
    BaseItem,
    BaseBasket,
    BaseModal
  },
  data() {
    return {
      offerItemsCount: 0,
      isShowOfferModal: false,
      activeOffersList: [],
      totalAmount: 0,
      sellersItems: [
        {
          discount: '31',
          id: '1',
          imageName: '1',
          price: '15',
          name: 'Awakened Demon Chest'
        },
        {
          discount: '31',
          id: '2',
          imageName: '2',
          price: '21',
          name: 'Set "Coral Islands"'
        },
        {
          discount: '31',
          id: '3',
          imageName: '3',
          price: '31',
          name: 'Fairy Chest'
        },
        {
          discount: '31',
          id: '4',
          imageName: '4',
          price: '39',
          name: 'Frost Chest'
        },
        {
          discount: '31',
          id: '5',
          imageName: '5',
          price: '12',
          name: 'Jade T hrone'
        },
        {
          discount: '31',
          id: '6',
          imageName: '6',
          price: '8',
          name: 'Dragon chest'
        }
      ],
      advantagesItems: [
        {
          image: 'key',
          title: 'Premium',
          desc: 'Premium subscription for buyers and sellers'
        },
        {
          image: 'money',
          title: 'Verification',
          desc: 'Sales and special offers'
        },
        {
          image: 'shield',
          title: 'Partners',
          desc: 'Lifetime referral program'
        },
        {
          image: 'phones',
          title: 'Transparency',
          desc: 'Simple and honest relationship with clients'
        }
      ]
    }
  },
  methods: {
    startPayment() {
      const redirectUrl = window.siteSettings.payment.redirectUrl;
      const token = window.siteSettings.payment.token;
      const paymentUrl = window.siteSettings.payment.url;

      const url = `${paymentUrl}/?paymentId=Ecom_ARCA_Olosa&redirectUrl=${redirectUrl}&token=${token}&paymentId=Ecom_ARCO&amount=${(this.totalAmount / 100).toFixed(0)}`;

      window.location.href = url;
    },
    countDown(item) {
      this.totalAmount = this.totalAmount - item.price;
      this.offerItemsCount = this.offerItemsCount - 1;
      const findedItem = {...item};

      for (let offer of this.activeOffersList) {
        if (findedItem.name === offer.name) {
          if (offer.count === 1) {
            offer.count = offer.count - 1;
            this.activeOffersList = this.activeOffersList.filter(el => el.name !== findedItem.name);
          } else {
            offer.count = offer.count - 1;
          }
        }
      }

      if(this.offerItemsCount === 0) {
        this.closeOfferModal();
      }
    },
    deleteOfferItem(item) {
      this.totalAmount = this.totalAmount - (item.price * item.count);
      this.offerItemsCount = this.offerItemsCount - item.count;
      const findedItem = {...item};

      this.activeOffersList = this.activeOffersList.filter(el => el.name !== findedItem.name);

      if(this.offerItemsCount === 0) {
        this.closeOfferModal();
      }
    },
    countUp(item) {
      this.totalAmount = this.totalAmount + item.price;
      this.offerItemsCount = this.offerItemsCount + 1;
      const findedItem = {...item};

      for (let offer of this.activeOffersList) {
        if (findedItem.name === offer.name) {
          offer.count = offer.count + 1;
        }
      }
    },
    addToBasket(item) {
      this.offerItemsCount++;
      this.totalAmount = this.totalAmount + item.price;

      this.addToActiveOffersList(item);
    },
    closeOfferModal() {
      this.isShowOfferModal = false;
    },
    openOfferModal() {
      this.isShowOfferModal = true;
    },
    addToActiveOffersList(item) {
      if (this.activeOffersList.find(offer => offer?.name === item.name) === undefined) {
        const newItem = { ...item, count: 1 };
        this.activeOffersList.push(newItem);
      } else {
        const newOffersList = this.activeOffersList.filter(el => el.name !== item.name);
        const newItem = {...this.activeOffersList.filter(el => el.name === item.name)[0]};
        newItem.count = newItem.count + 1;
        newOffersList.push(newItem);

        this.activeOffersList = newOffersList;
      }
    }
  },
  computed: {
    currentYear() {
      return (new Date()).getFullYear();
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .header,
  .special-offer {
    width: 100%;
    display: flex;
    justify-content: center;
    background-image: url('../../public/custom/images/header-bg-pic.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    background-position: center;
  }

  .special-offer {
    background-image: url('../../public/custom/images/main-bg-pic.jpg');
  } 

  .block-container {
    width: 1260px;
    max-width: 95%;
    display: flex;
    flex-direction: column;
  }

  .header-container {
    margin-bottom: 100px;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 140px;
    }
  }

  .header-nav {
    display: flex;
  }

  .header-link {
    font-weight: 400;
    font-size: calc(8px + 12 * (100vw / 1920));
    line-height: 24px;
    text-decoration: none;
    margin-left: calc(7px + 33 * (100vw / 1920));
    transition: all .2s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  .header-title-container {
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
  }

  .header-title-item {
    font-weight: 700;
    font-size: calc(20px + 30 * (100vw / 1920));
    line-height: 61px;
  }

  .header-offer-container {
    margin-bottom: 200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .header-offer-block {
    display: flex;
    flex-direction: column;
  }

  .header-offer-block-title,
  .special-offer-title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 27px;
    line-height: 33px;
  }

  .header-offer-block-price-block,
  .special-offer-block-price-block {
    margin-bottom: 27px;
    font-weight: 600;
    font-size: 38px;
    line-height: 46px;
    display: flex;
  }

  .header-price,
  .special-offer-price {
    margin-right: 21px;
    font-weight: 600;
    font-size: 38px;
    line-height: 46px;
  }

  .header-discount,
  .special-offer-discount {
    margin-top: 5px;
    width: 47px;
    height: 30px;
    background: #FFE745;
    border-radius: 8px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }

  .header-offer-btn-block,
  .special-offer-btn-block {
    width: 280px;
    height: 50px;
    background: var(--pay-btn-bg-color);
    border-radius: 10.8718px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease;
    cursor: pointer;
    user-select: none;

    img {
      margin-right: 17px;
    }

    &:hover {
      transform: scale(1.01);
    }
  }

  .header-offer-btn-text,
  .special-offer-btn-text {
    font-weight: 500;
    font-size: 17.6667px;
    line-height: 22px;
  }

  .main,
  .release-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background: var(--main-bg-color);
  }

  .sellers-heading-container,
  .choise-title,
  .release-title {
    margin-top: 20px;
    margin-bottom: 28px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 27px;
    line-height: 33px;
  }

  .sellers-item {
    width: 380px;
    height: 278px;
    display: flex;
    flex-direction: column;
  }

  .sellers-item-container {
    width: 100%;
    height: 218px;
  }

  .sellers-item-name {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  .sellers-items-block {
    margin-bottom: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .advantages-block {
    margin-bottom: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .advantages-item {
    margin-bottom: 20px;
    width: 280px;
    padding: 31px 0 43px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background: #1C133A;
    border-radius: 16px;
  }

  .advantages-img {
    height: 40px;
    width: 40px;
    margin-bottom: 27px;
  }

  .advantages-title {
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  .advantages-desc {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }

  .choise-block {
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .choise-items-block {
    display: flex;
    justify-content: space-between;
  }

  .choise-items-container {
    width: calc(50% - 10px);
    display: flex;
    flex-direction: column;
  }

  .big-choise-item {
    width: 100%;
    height: 346px;
    margin-bottom: 10px;
  }

  .small-choise-item {
    width: 100%;
    height: 240px;
    margin-bottom: 10px;
  }

  .special-offer-container {
    margin-top: 230px;
    margin-bottom: 240px;
    display: flex;
    flex-direction: column;
  }

  .release-items-block {
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
  }

  .release-items-container-big {
    width: calc(60% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .release-items-container-small {
    width: calc(40% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .big-release-item-container {
    width: 100%;
    height: 554px;
    display: flex;
    flex-direction: column;
  }

  .big-release-item {
    width: 100%;
    height: 500px;
  }

  .big-release-item-name {
    height: 54px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
  }

  .small-release-item-container {
    height: calc(50% - 10px);
    width: 100%;
  }

  .small-release-item {
    width: 100%;
    height: calc(100% - 54px);
  }

  .footer {
    width: 100%;
    background: var(--footer-bg-color);
    display: flex;
    justify-content: center;
  }

  .footer-block {
    margin-top: 85px;
    margin-bottom: 85px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    img {
      width: 140px;
    }
  }

  .footer-legal-block {
    width: 282px;
    
    .legal-desc {
      margin-bottom: 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #89849D;
    }

    .legal-name {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #BFB9D7;
    }
  }

  .footer-nav {
    display: flex;
    flex-direction: column;
  }

  .footer-nav-link {
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    color: #89849D;
    text-decoration: none;
  }

  .modal-block {
    width: 480px;
    display: flex;
    flex-direction: column;
  }

  .modal-title {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid white;
    font-size: 24px;
    line-height: 1.35;
    font-weight: 600;
    color: white;
  }

  .modal-offers-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .modal-offer-item {
    margin-bottom: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
  }

  .offer-item-name {
    width: 40%;
    margin-right: 5%;
    font-size: 16px;
    font-weight: 600;
    color: white;
  }

  .count-block {
    width: 20%;
    margin-right: 5%;
    display: flex;
    justify-content: space-between;
  }

  .count-down,
  .count-up,
  .delete-btn {
    height: 16px;
    width: 16px;
    border: 1px solid white;
    box-sizing: border-box;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-size: 13px;
    line-height: 13px;
    user-select: none;
  }

  .delete-btn {
    margin-left: 20%;
  }

  .amount-block {
    width: 30%;
    display: flex;
    justify-content: flex-end;
  }

  .modal-btn {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    font-weight: 600;
    background: var(--modal-pay-btn-bg-color);
    cursor: pointer;
    transition: all .2s ease;

    &:hover {
      transform: scale(1.01);
    }
  }

  @media screen and (max-width: 1270px) {
    .header {
      background-position: right;
    }
  }

  @media screen and (max-width: 1070px) {
    .header {
      background-position: top;
    }
  }

  @media screen and (max-width: 720px) {
    .special-offer {
      background-position: top;
    }
    .sellers-items-block {
      justify-content: center;
    }

    .advantages-block {
      flex-direction: column;
      align-items: center;
    }

    .advantages-item {
      width: 80%;
    }

    .choise-items-block {
      flex-direction: column;
    }

    .choise-items-container {
      width: 100%;
    }
  }

  @media screen and (max-width: 900px) {
    .big-choise-item {
      width: 100%;
      height: 240px;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 770px) {
    .release-items-block {
      flex-direction: column;
      align-items: center;
    }

    .release-items-container-big,
    .release-items-container-small {
      width: 100%;
    }

    .small-release-item-container {
      height: 240px;
    }
  }

  @media screen and (max-width: 670px) {
    .footer-block {
      flex-direction: column;
      margin-top: 40px;
      margin-bottom: 40px;

      img {
        margin-bottom: 20px;
      }
    }

    .legal-name {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 640px) {
    .modal-block {
      width: 75vw;
    }

    .basket-container {
      right: 25px;
    }

    .delete-btn {
      margin-left: 10%;
    }
  }

  @media screen and (max-width: 490px) {
    .header-link {
      display: none;
    }
  }
</style>